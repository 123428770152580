import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import useApi from '../../hooks/UseApi';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthentication from '../../auth/useAuthentication';
import Config from '../../config/config';
import styles from './Filters.module.scss';
import { X, PencilSquare } from 'react-bootstrap-icons';


function GridComplexExample() {

  const navigate = useNavigate();
  const context = useAuthentication();
  const { get, post, updating, showError } = useApi();


  const [ validated, setValidated ] = useState(false);
  const [ group, setGroup ] = useState('');
  const [ order, setOrder ] = useState(0);
  const [ value, setValue ] = useState('');
  const [ values, setValues ] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      get('filters/' + id).then(r => {
        setGroup(r.data.group);
        setOrder(r.data.order);
        setValues(r.data.filters_values);
      });
    }
  }, []);


  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };


  const editValue = (event, item) => {
    const newVal = prompt("Introduzca el nuevo valor", item.value);
    if (newVal != null) {
      post('filters/value', { id: item.id, value: newVal }).then(r => {
        item.value = newVal;
      }).catch(err => {
        console.log(err);
      });
    }
  };

  const removeFromValues = (event, val) => {
    event.preventDefault();
    const valuesClone = [ ...values ];
    setValues(valuesClone.filter(item => { return item.id != val}));
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (values.map(v => v.value).indexOf(event.target.value) === -1) {
        const valuesClone = [ ...values ];
        valuesClone.push({ id: 'NEW-' + event.target.value, value: event.target.value });
        setValues(valuesClone);
        setValue('');
      } else {
        showError('El valor ya existe')
      }
    } else {
      setValue(event.target.value)
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (group == '' || (id && values.length == 0)) {
      setValidated(true);
      if (values.length == 0) {
        showError('Debe cargar al menos un valor');
      }
    } else {

      const data = {
        machine: context.user.selectedMachine.id,
        id,
        order,
        group,
        values
      };

      post('filters', data).then(r => {
        navigate(-1);
      }).catch(err => {
        console.log(err);
      });
    }
  };

  return (
      <Form noValidate validated={validated}>


      <Row className="">
        <Col className='col-8'>

          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Grupo</Form.Label>
            <Form.Control required type="text" value={group} onChange={handleChange(setGroup)} />
            <div className="invalid-feedback">El grupo es requerido</div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Orden</Form.Label>
            <Form.Control required type="text" value={order} onChange={handleChange(setOrder)} />
            <div className="invalid-feedback">El orden es requerido</div>
          </Form.Group>

          { id && <>
            <Form.Group className="mb-3" controlId="formGridAddress2">
              <Form.Label>Valor</Form.Label>
              <Form.Control required={values.length == 0} type="text" value={value} onKeyDown={handleKeyDown} onChange={handleChange(setValue)} />
              <div className="invalid-feedback">Debe agregar al menos un valor</div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress1">
              <div className="btn-group-vertical xw-100 mb-4" role="group" aria-label="Vertical radio toggle button group">
                { values.map((item) => {
                  return <button key={item.id} type="button" className="btn btn-primary position-relative my-2">
                    { item.value }
                    <span onClick={e => removeFromValues(e, item.id)} className="position-absolute top-0 start-100 translate-middle px-1 bg-danger border border-light rounded-circle"><X /></span>
                    <span onClick={e => editValue(e, item)} className="position-absolute top-0 start-0 translate-middle px-1 bg-primary border border-light rounded-circle"><PencilSquare /></span>
                  </button>
                }) }
              </div>
            </Form.Group>
            </> }
        </Col>
      </Row>




      <div className='d-flex justify-content-end'>
        <Button onClick={e => handleSubmit(e)} variant="primary" type="button" className="me-2">Guardar</Button>
        <Button onClick={e => navigate(-1)} variant="secondary">Cancelar</Button>
      </div>
    </Form>
  );
}

export default GridComplexExample;