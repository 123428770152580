import React, { Fragment, useEffect, useRef, useState } from 'react';
import Config from '../../config/config';
import useApi from '../../hooks/UseApi';
import styles from './Catalog.module.scss';
import { Search, XLg, Cart, Trash3, Funnel, CheckCircle } from 'react-bootstrap-icons';
import useAuthentication from '../../auth/useAuthentication';
import mercadoPago from '../../assets/mercado-pago.png';
import modo from '../../assets/modo.png';
import StillHereModal from './StillHereModal';
import DetailModal from './DetailModal';
import IncDecCounter from "../../components/IncDecCounter";
import Accordion from 'react-bootstrap/Accordion';
import QRCode from "react-qr-code";
import Badge from 'react-bootstrap/Badge';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIdleTimer } from 'react-idle-timer/legacy'
import { toast } from 'react-toastify';
import { QrReader } from 'react-qr-reader';
import ReactSlider from 'react-slider';
import styled from 'styled-components';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
// import layout from "simple-keyboard-layouts/build/layouts/spanish";



const StyledSlider = styled(ReactSlider)`
    width: 95%;
    height: 20px;
`;

const StyledThumb = styled.div`
    // height: 20px;
    padding:4px;
    // line-height: 25px;
    // width: 25px;
    text-align: center;
    font-size: 11px;
    background-color: #0B4496;
    color: #fff;
    border-radius: 25%;
    cursor: grab;
    // text-wr
    white-space: nowrap;
    ::before {
      content: '$ ';
      display: inline-block;
    }
`;

const Thumb = (props, state) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>;

const StyledTrack = styled.div`
    top: 6px;
    bottom: 0;
    background: ${props => (props.index === 2 ? '#ddd' : props.index === 1 ? '#ABFFC6' : '#ddd')};
    // border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;


// custom hook for getting previous value
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  },[value]);
  return ref.current;
};

const Catalog = (props) => {

  const context = useAuthentication();
  const [ product, setProduct ] = useState();
  const [ readedQrData, setReadedQrData ] = useState();
  const [ showQrReader, setShowQrReader ] = useState(false);
  // work around https://stackoverflow.com/questions/65082310/programmatically-collapse-all-react-accessible-accordion-panels
  const [ accordionKey, setAccordionKey ] = useState(1);
  const [ data, setData ] = useState([]);
  const [ cart, setCart ] = useState([]);
  const [ showCart, setShowCart ] = useState(false);
  const [ showFilters, setShowFilters ] = useState(false);
  const [ priceMax, setPriceMax ] = useState();
  const [ priceMin, setPriceMin ] = useState();
  const { get, post, updating } = useApi();
  const [ page, setPage ] = useState(1);
  const [ totalPages, setTotalPages ] = useState(null);
  const [ totalRecords, setTotalRecords ] = useState(null);
  const [ qrData, setQrData ] = useState();
  const [ order, setOrder ] = useState(null);
  const [ randomCartUrl, setRandomCartUrl ] = useState('');
  const [ marginOverCost, setMarginOverCost ] = useState(0);

  const [ iddleModalOpen, setIddleModalOpen ] = useState(false);
  const [ show, setShow ] = useState(false);

  const [ keyboard, setKeyboard ] = useState(false);
  const [ search, setSearch ] = useState('');
  const [ priceFilter, setPriceFilter ] = useState([1, 999999]);
  const [ options, setOptions ] = useState([]);
  const [ groups, setGroups ] = useState([]);
  const [ filters, setFilters ] = useState({});
  const [ onlyWithStockFiltersSwitchState, setOnlyWithStockFiltersSwitchState] = useState(true);
  const prevOnlyWithStockFiltersSwitchState = usePrevious(onlyWithStockFiltersSwitchState);

  const vh = window.innerHeight;


  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  const isMobile = width <= 768;
  const isTotem = () => {
    try {
      const url = window.location.host;
      let domain = url;
      if (url.includes("://")) {
        domain = url.split('://')[1];
      }
      const subdomain = domain.split('.')[0];
      return subdomain.indexOf('device-'.toLowerCase()) > -1;
    } catch(err) {
      return false;
    }
  };


  const navigate = useNavigate();
  let statusInterval;

  const doReset = () => {
    console.log('doReset')
    setIddleModalOpen(false);
    setShowQrReader(false);
    setKeyboard(false);
    setPage(1);
    setData([]);
    setSearch('');
    setShowCart(false);
    setShow(false);
    setFilters({});
    setPriceFilter([priceMin, priceMax]);
    console.log(priceMin, priceMax)
    setCart([]);
    setOrder(null);
    clearInterval(statusInterval);
    setOnlyWithStockFiltersSwitchState(true);
    setAccordionKey(accordionKey + 1);
  };

  const onPresenceChange = (presence) => {
    const isIdle = presence.type === 'idle';
    const isPrompted = presence.type === 'active' && presence.prompted;
    const isActive = presence.type === 'active' && !presence.prompted;

    if (isIdle) {
      console.log('Iddle')
      doReset();
    } else if (isPrompted) {
      console.log('Prompted')
      if (cart.length > 0) {
        setIddleModalOpen(true);
      }
    } else if (isActive) {
      console.log('Active')
    }
  };


  const { getRemainingTime, reset } = useIdleTimer({
    onPresenceChange,
    // timeout: 10_000, // 10 sec
    // promptBeforeIdle: 5_000, // 5 sec
    timeout: 180_000, // 3 minutes
    promptBeforeIdle: 120_000, // 2 minutes
    throttle: 200,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      // 'visibilitychange'
    ]
  });



  const handleClose = () => setShow(false);

  const handleFilterChange = (event) => {
    let filtersClone = { ...filters };
    if (!filtersClone[event.target.name]) {
      filtersClone[event.target.name] = [ ];
    }
    setData([]);
    setPage(1);
    const index = filtersClone[event.target.name].indexOf(event.target.value);
    if (index > -1) {
      filtersClone[event.target.name].splice(index, 1);
    } else {
      filtersClone[event.target.name].push(event.target.value);
    }
    if (filtersClone[event.target.name] && filtersClone[event.target.name].length === 0) {
      delete filtersClone[event.target.name];
    }
    setFilters(filtersClone);
  };


  useEffect(() => {
    setData([]);
    setPage(1);
  }, [ search ]);

  const getData = () => {

    if (prevOnlyWithStockFiltersSwitchState != onlyWithStockFiltersSwitchState && page > 1) {
      return;
    }

    const options = { page, filters };
    options.filters.price = priceFilter;
    if (search.length > 0) {
      options.filters.search = search;
    } else {
      delete options.filters.search;
    }


    get('products/catalog?onlyWithStock=' + onlyWithStockFiltersSwitchState, options).then(r => {
      setTotalRecords(r.headers['x-paging-total-records'] * 1);
      let tmpCart = { };
      cart.forEach(p => {
        tmpCart[p.id] = p;
      });
      r.data.forEach(p => {
        if (tmpCart[p.id]) {
          p.quantityInTheCart = tmpCart[p.id].quantity;
        }
      });
      if (!totalPages) {
        setTotalPages(r.headers['x-paging-total-pages'] * 1);
      }

      if (page == 1) {
        setData(r.data);
      } else {
        setData(oldArray => [...oldArray, ...r.data]);
      }

      setShowCart(false);
    });
  };
  useEffect(getData, [ props.location, filters, priceFilter, search, page, onlyWithStockFiltersSwitchState, (context.user && context.user.selectedMachine.id)]);

  useEffect(() => {
    setQrData(null);
  }, [ cart ]);

  useEffect(() => {
    setData([]);
    setPage(1);
    get('filters/all/' + onlyWithStockFiltersSwitchState).then(r => {
      const maxPrice = parseInt(r.data.price.max) + 1;
      setPriceMax(maxPrice);
      let minPrice = (Math.ceil(r.data.price.min) - 1) < 0 ? 0 : (Math.ceil(r.data.price.min) - 1);
      if ((!context.user || !context.user.id) && minPrice === 0) {
        minPrice = 1;
      }
      setPriceMin(minPrice);
      setPriceFilter([minPrice, maxPrice]);

      const tmpGroups = [ ];
      const options = { };
      r.data.groups.forEach(e => {
        tmpGroups.push({ id: e.id, name: e.group });
        if (!options[e.group]) {
          options[e.id] = [ ];
        }
        options[e.id] = e.filters_values.map( v => { return { id: v.id, value: v.value }} );
      });
      setGroups(tmpGroups);
      setOptions(options);
    });
  }, [ onlyWithStockFiltersSwitchState, (context.user && context.user.selectedMachine.id) ]);


  const fetchMoreData = async () => {
    const newPage = page + 1;
    setPage(newPage);
  }

  const doAddToCart = (product, quantity) => {

    product.quantity = quantity;
    product.total = quantity * product.price;

    let cartClone = [ ...cart ];
    const index = cartClone.findIndex(object => {
      return object.id === product.id;
    });
    product.quantityInTheCart = quantity;
    if (index > -1) {
      if (quantity === 0) { // remove
        cartClone.splice(index, 1);
        delete product.quantityInTheCart;
      } else {
        cartClone[index] = product;
      }
    } else {
      cartClone.push(product);
    }
    setCart(cartClone);
  };

  const setFilterPrice = (value) => {
    setPriceFilter(value);
    setPage(1);
    setData([]);
  };

  const remove = (product) => {
    doAddToCart(product, 0);
  };

  const doPay = () => {
    let uri = 'qr/generate/';
    if (context.user) {
      uri += context.user.selectedMachine.id;
    } else {
      uri += Config.MACHINE_ID;
    }
    post(uri, { items: cart }).then(r => {
      setQrData({qrImage: r.data.qr});
      if (statusInterval) {
        clearInterval(statusInterval);
      }
      statusInterval = setInterval(() => {
        const orderId = r.data.orderId;
        get('orders/status/' + orderId).then(r => {
          if (r.data.order.paymentTimeout == 0) {
            doReset();
          } else if (r.data.order.status == 'COMPLETED') {
            doReset();
          } else {
            setOrder(r.data.order);
          }
        });
      }, 1000);
    });
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const clearSearchHandler = () => {
    setPage(1);
    setData([]);
    setSearch('');
  };

  const clearFiltersHandler = () => {
    setPage(1);
    setData([]);
    setFilters({});
  };

  const getCartTotal = () => {
    return cart.reduce((accumulator, object) => {
      return accumulator + object.total;
    }, 0)
  };

  const showDetail = (product) => {
    setShowCart(false);
    setProduct(product);
    setShow(true);
  };


  const handleIddleClose = () => setIddleModalOpen(false);
  const handleIddleWait = () => {
    reset();
  };



  // margins
  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };
  const applyMargin = (w) => {
    let data = { type: w, filters };
    if (w == 'cost') {
      data.margin = marginOverCost;
    }
    if (search.length > 0) {
      data.filters.search = search;
    } else {
      delete data.filters.search;
    }

    // remove filters if empty
    if (Object.keys(data.filters).length === 0 && data.filters.constructor === Object) {
      delete data.filters;
    }

    const text = "Vas a modificar el precio de " + totalRecords + ' producto/s. Estas seguro?';
    if (window.confirm(text) == true) {
      post('products/updatePrice', data).then(r => {
        toast.success(<div>Se actualizaron los precios correctamente</div>);
        setData([]);
        setTimeout(getData(), 0);
      }).catch(err => {
        console.log(err);
      });
    }
  };


  // MOBILE ONLY
  useEffect(() => {
    if (isMobile && readedQrData) {
      let uri = 'totem/random/';
      if (context.user) {
        uri += context.user.selectedMachine.id;
      } else {
        uri += Config.MACHINE_ID;
      }
      post(uri, { items: cart, random: readedQrData }).then(r => {
        toast.success(<div>Tu carrito se cargo en el totem correctamente. Pagá y terminá tu compra ahí</div>);
        doReset();
      }).catch(err => {
        alert(JSON.stringify(err))
      });
    }
  }, [readedQrData]);



  // TOTEM ONLY
  useEffect(() => {
    if (isMobile) {
      return;
    }
    if (!isTotem()) {
      return;
    }
    // const checkForRemoteCart = () => {
    //   let uri = 'totem/random/';
    //   if (context.user) {
    //     uri += context.user.selectedMachine.id;
    //   } else {
    //     uri += Config.MACHINE_ID;
    //   }

    //   get(uri).then(r => {
    //     if (r.data.cart) {
    //       setCart(r.data.cart);
    //       setShowCart(true);
    //     } else {
    //       setRandomCartUrl(r.data.options);
    //     }
    //   });
    // };

    // const interval = setInterval(checkForRemoteCart, 2000 * 1);
    const interval = setInterval(() => {
      let uri = 'totem/random/';
      if (context.user) {
        uri += context.user.selectedMachine.id;
      } else {
        uri += Config.MACHINE_ID;
      }

      get(uri).then(r => {
        console.log(r)
        if (r.data.cart) {
          setCart(r.data.cart);
          setShowCart(true);
        } else {
          setRandomCartUrl(r.data.options);
        }
      });
    }, 2000 * 1);

    // clean up interval on unmount
    return () => {
      clearInterval(interval);
    };
  }, []);





  // VIRTUAL KEYBOARD CONFIG
  const customLayout = {
    default: [
      "1 2 3 4 5 6 7 8 9 0",
      "Q W E R T Y U I O P {bksp}",
      "A S D F G H J K L",
      "Z X C V B N M {hide}",
      "{space}"
    ]
  };

  const customDisplay = {
    "{bksp}": "⌫",
    "{space}": "  ",
    "{hide}": "Ocultar Teclado ▼",
  };


  useEffect(() => {
    const clickHanlder = (e) => {
      if (
        !(e.target.nodeName === "INPUT") &&
        !e.target.classList.contains("hg-button")
      ) {
        setKeyboard(false);
      }
    };
    window.addEventListener("click", clickHanlder);
    return window.removeEventListener("click", clickHanlder, true);
  }, []);



  // const onChange = (input) => {
  //   console.log("Input changed", input);
  // }

  const onKeyPress = (button) => {
    // setSearch(button)
    let text;
    if (button == '{bksp}' && search && search.length > 0) {
      text = search.substring(0, search.length - 1);
    } else if (button == '{hide}') {
      setKeyboard(false);
    } else if (button == '{space}') {
      text = search + ' ';
    } else {
      text = search + button;
    }
    if (text) {
      // alert('xxx')
      setSearch(text);
    }
    console.log("Button pressed", button);
  }


  return (
    <>

      {/* <div onClick={e => doReadCartQrCode()}>xxxxxxxxxxxx</div> */}

      <StillHereModal getRemainingTime={getRemainingTime} show={iddleModalOpen} handleIddleClose={handleIddleClose} handleIddleWait={handleIddleWait} />

      {/* deliveryTimeout */}
      <DetailModal product={product} show={show} handleClose={handleClose} doAddToCart={doAddToCart} setShowCart={setShowCart} />

      <div className="container text-center">

        <div className="row">

          {/* CART */}
          { showCart && <div className={ `${styles.cart} row text-start align-self-start p-3 flex-nowrap flex-column` }>

            <div className={ `${styles.title}` }>
              <Cart className='me-2'/>Carrito de compras
              <button onClick={e => setShowCart(false)} type="button" className="float-end btn-close" aria-label="Close"></button>
            </div>


            <div className={ `${styles.total} row` }>
              { !qrData && (!order || (order && order.status == 'PENDING')) && <>
                <div className='col-lg-4 col-12 ps-5 pt-1 mb-3 mb-lg-0 text-lg-start text-center text-nowrap'>
                  Total $ { getCartTotal() }
                </div>

                { showQrReader && getCartTotal() > 0 && <>
                  <QrReader
                    constraints={{
                      facingMode: 'environment'
                    }}
                    onResult={(result, error) => {
                      if (!!result) {
                        setReadedQrData(result?.text);
                      }
                      if (!!error) {
                        console.info(error);
                      }
                    }}
                    style={{ width: '100%' }}
                  />
                  {/* <p>{readedQrData}</p> */}
                </> }

                <div className={ `${styles.wallet} col-lg-8 col-12 text-lg-end text-center pe-lg-5 d-none d-lg-block` }>
                  <img src={mercadoPago} className={ `${styles.wallet_img} me-3` } />
                  <img src={modo} className={ `${styles.wallet_img}` } />
                </div>

                <div className='col-12 pt-4 ps-5 pe-5'>
                  <div className='row mt-2'>
                    <div className='d-grid gap-2 col-6 mx-auto'>
                      { isTotem() && getCartTotal() > 0 && !updating && <button onClick={e => doPay()} type="button" className="btn btn-primary d-none d-lg-block d-sm-none">Pagar</button> }
                      { getCartTotal() > 0 && !updating && <button onClick={e => setShowQrReader(true)} type="button" className="btn btn-primary d-lg-none d-sm-block">Cargar mi carrito en el Totem</button> }
                      { updating && <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        <span role="status">&nbsp;Generando QR...</span>
                      </button> }
                      { getCartTotal() == 0 && <p className='mt-3'>El Carro esta vacio</p> }
                    </div>
                    <div className='d-grid gap-2 col-6 mx-auto'>
                      <button onClick={e => setShowCart(false)} type="button" className="btn btn-outline-primary">Seguir Comprando</button>
                    </div>
                  </div>

                  { isTotem() && getCartTotal() == 0 && <div className='row mt-5 d-none d-lg-block'>
                    <div className='d-grid gap-3 col-12'>
                      <QRCode
                        className='mx-auto'
                        size={256}
                        value={ randomCartUrl }
                        viewBox={`0 0 256 256`}
                        />
                      <p className='text-center'>Si tenes el carrito en tu teléfono, escanea este codigo QR</p>
                    </div>
                  </div> }

                </div>
              </> }

              { qrData && (!order || order.status == 'PENDING') && <div className="row">
                <div className="col-12 text-center">
                  <img src={mercadoPago} className={ `pe-1 ${styles.wallet_img}` } />
                  <img src={modo} className={ `ps-1 ${styles.wallet_img}` } />
                </div>
                <div className="col-12 text-center">
                  <img src={qrData?.qrImage} className={ `${styles.qr}` }/>
                  <p className='pt-2'>Por favor, escanee el código QR con su billetera preferida para finalizar la compra</p>
                </div>
              </div> }

              { order && <div className={ `${styles.progress} row pt-4 ps-5 pe-5` }>
                <div className='col-12 text-nowrap text-truncate  pt-2 pb-2'>
                  { order.status == 'PENDING' && <>
                    <div className="spinner-border" role="status"></div>
                    <span className='ps-2'>Esperando el pago ({ order.paymentTimeout })...</span>
                  </> }
                  { order.status == 'PAYED' && <>
                    <CheckCircle className={ `${styles.check}` } />
                    <span className='ps-2'>Recibimos el pago</span>
                  </> }
                </div>

                { order.status == 'PAYED' && order.orders_details.map(detail => {
                  return <div className='col-12 text-nowrap text-truncate pt-2 pb-2'>
                    { detail && !detail.totem_id && <div className="spinner-border" role="status"></div> }
                    { detail && !detail.totem_id && <span className='ps-2'>Procesando { detail.product.name }...</span> }
                    { detail && detail.totem_id && detail.status != 'COMPLETED' && <div className="spinner-border" role="status"></div> }
                    { detail && detail.totem_id && detail.status != 'COMPLETED' && <span className='ps-2'>Entregando { detail.product.name }...</span> }

                    { detail && detail.status == 'COMPLETED' && <CheckCircle className={ `${styles.check}` } /> }
                    { detail && detail.status == 'COMPLETED' && <span className='ps-2'>Entregamos { detail.product.name }</span> }
                  </div>})
                }
              </div> }
            </div>



            { cart.map(item => {
              return <div className={ `${styles.item} row`} key={item.id}>
                <div className='col'>
                  <div className='row'>
                    <div className={ `${styles.name} col-12` }>{item.name}</div>
                    <div className={ `${styles.description} col-12` }>{item.description}</div>
                    <div className={ `${styles.description} col-12` }>Disponible: {item.stock}</div>
                  </div>
                  <div className='row flex align-items-baseline p-2'>
                    {/* <div className='col-3'><img src={ Config.API.BASE_URI + 'images/' + item.image } alt={item.name} className={ `${styles.image}` } /></div> */}
                    <div className='col-3'><img src={ item.image } alt={item.name} className={ `${styles.image}` } /></div>
                    <div className='col-4'>
                      <IncDecCounter updateQuantity={doAddToCart} item={item} />
                    </div>
                    <div className='col-2'>
                      <button onClick={e => remove(item)} type="button" className="btn btn-outline-primary"><Trash3 /></button>
                    </div>
                    <div className={ `${styles.total} col-3 text-end ps-2 pe-2 text-nowrap`}>
                      $ {item.total}
                    </div>
                  </div>
                </div>
              </div>
            }) }

          </div> }

          {/* FILTERS */}
          <div className="col-lg-3 col-sm-12">

            <div className={ `${styles.title} row` }>
              <div className={ `${styles.titlex} col-12 text-start` }>
                Mostrando <strong>{ totalRecords }</strong> productos
              </div>
              <div className={ `${styles.titlex} col-12 text-start` }>
                Filtrando por:
                { filters && Object.keys(filters).length > 0 && <span onClick={e => clearFiltersHandler()} role="button" className="float-end"><XLg /></span> }
                { JSON.stringify(filters) == '{}' &&
                  <button onClick={e => setShowFilters(!showFilters)} type="button" className="btn btn-small btn-primary position-relative float-end d-lg-none">
                    <Funnel />
                  </button>
                }
              </div>
              <div className={ `${styles.titlex} col-12 text-start` }>
                <h6><small>{ Object.keys(filters).filter(e => { return e != 'search' && e != 'price' }).map(e => {
                  return <>
                    <div className='mt-1'><b>{ groups.filter(f => { return f.id == e }).pop().name }:</b></div>
                    { options[e].filter(f => {return filters[e].indexOf(f.id) > -1}).map(ee => {
                      return <div className='ms-1'>{ee.value}</div>
                    }) }
                  </>
                }) } </small></h6>
              </div>
            </div>


            <div className={"row d-lg-block " + (showFilters ? 'd-block' : 'd-none')}>
              <div className="col" key={accordionKey}>
                <Accordion defaultActiveKey="0">
                {
                  groups && groups.map(group => {
                    return <Accordion.Item eventKey={ group.id } key={ group.id }>
                      <Accordion.Header><span className='pe-3'>{ group.name }</span> { filters[group.id] && filters[group.id].length > 0 && <Badge bg="primary">{ filters[group.id].length }</Badge> }
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="btn-group-vertical w-100 mb-4" role="group" aria-label="Vertical radio toggle button group">
                        { options[group.id].map((e) => {
                          return <Fragment key={e.id}>
                            <input onChange={ e => handleFilterChange(e) } type="checkbox" className="btn-check" checked={ filters[group.id] && filters[group.id].indexOf(e.id) > -1 } name={ group.id } value={ e.id } id={ group.id + '-' + e.id } autoComplete="off" />
                            <label className="btn btn-outline-primary text-start" htmlFor={group.id + '-' + e.id}>{e.value}</label>
                          </Fragment>
                        }) }
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  })
                }
                </Accordion>
              </div>


              <div className="row pt-3 pe-0">
                <div className="col">
                  <div className="form-check form-switch form-check-reverse">
                    <Form.Check
                      type="switch"
                      id="flexSwitchCheckChecked"
                      label="Sólo con Stock"
                      checked={onlyWithStockFiltersSwitchState}
                      // defaultChecked={onlyWithStockFiltersSwitchState}
                      onChange={e => setOnlyWithStockFiltersSwitchState(!onlyWithStockFiltersSwitchState)}
                    />
                  </div>
                </div>
              </div>

            </div>

            <div className="row pt-2">
              <div className="col">
                <div className="col text-start">
                  Precio
                  </div>
                  {priceMax && <StyledSlider onAfterChange={setFilterPrice} value={priceFilter} defaultValue={priceFilter} step={priceMax > 100 ? parseInt(priceMax / 100) : 1} min={priceMin} max={priceMax} renderTrack={Track} renderThumb={Thumb} /> }
                  </div>
            </div>


            <div className="row">
              <div className="col">
                { context && context.user && <div className="row mt-3 pt-3 pe-0 text-start">
                    <div className="col">
                      <label className="fw-bold form-label">Actualizar Precios</label>
                      <div className="row mb-3">
                        <label className="form-label">% sobre P. Compra</label>
                        <div className="col-6">
                          <input type="email" className="text-end form-control" placeholder="%" value={marginOverCost} onChange={handleChange(setMarginOverCost)} />
                        </div>
                        <div className="col-6">
                        <button onClick={e => applyMargin('cost')} type="button" className="btn btn-primary">Aplicar</button>
                        </div>
                      </div>
                      {/* <div className="row mb-3">
                        <label className="form-label">% sobre P. Venta</label>
                        <div className="col-6">
                          <input type="email" className="text-end form-control" placeholder="%" value={marginOverSell} onChange={handleChange(setMarginOverSell)} />
                        </div>
                        <div className="col-6">
                          <button onClick={e => applyMargin('sell')} type="button" className="btn btn-primary">Aplicar</button>
                        </div>
                      </div>
                      <div id="passwordHelpBlock" className="form-text"><span className="fw-bold">Atención:</span> Los Porcentajes se aplicarán sobre todos los productos a los que aplique el filtro</div> */}

                    </div>
                </div> }
              </div>
            </div>

          </div>

          {/* PRODUCTS */}
          <div className="col-lg-9 col-sm-12">
            <div className="row">
              <div className="col">
                <div className="input-group input-group-lg mb-3 mt-lg-0 mt-3">
                  <span className="input-group-text" id="basic-addon1"><Search /></span>
                  <input onFocus={e => setKeyboard(true)} type="text" value={search} onChange={handleSearch} className="form-control" placeholder="Buscar..." aria-label="Username" aria-describedby="basic-addon1" />
                  { search && search.length > 0 && <span onClick={e => clearSearchHandler()} role="button" className="input-group-text"><XLg /></span> }

                  { (!isTotem() || getCartTotal() > 0) && <button onClick={e => setShowCart(true)} type="button" className="btn btn-primary position-relative">
                    <Cart />
                    {
                      cart.length > 0 && <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        { cart.length }
                      </span>
                    }
                  </button> }
                  { isTotem() && getCartTotal() == 0 && <button onClick={e => setShowCart(true)} type="button" className="btn btn-outline-primary position-relative">
                    <span>Pasa el 🛒 de tu 📱</span>
                    {
                      cart.length > 0 && <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        { cart.length }
                      </span>
                    }
                  </button> }
                </div>
              </div>
            </div>

            { data && data.length > 0 &&
              <div id="scrollableDiv" style={{ height: vh, height: '80vh', overflow: "auto" }}>
              <InfiniteScroll
                dataLength={data.length}
                next={fetchMoreData}
                hasMore={!(totalPages == page)}
                height={vh}
              >
                <div className="row">
                  {
                    data && data.map(product => {
                      return <div key={ product.id } className="col-lg-4 col-6 p-2" onClick={e => showDetail(product)}>
                        <div key={ product.id } className={ `${styles.card} card p-1` }>
                          {/* <img src={ Config.API.BASE_URI + 'images/' + product.image } alt={product.name} className={ `${styles.image} card_img_top` } /> */}
                          <img src={ product.image } alt={product.name} className={ `${styles.image} card_img_top` } />
                          <div className="card-body text-start">
                            <h2 className={ `${styles.crop} fs-6 fw-bold text-start`}>{ product.name }</h2>
                            <p className={ `${styles.crop} ${styles.description} card-text` }>{ product.description }</p>
                            <h5 className="card-title">$ { product.price }</h5>
                            { product.stock > 0 && <p className={styles.stock}>Disponible: { product.stock }</p> }
                            { !product.stock && <p className={styles.stock}>No Disponible</p> }
                            <div className="row mt-3">
                              { product.stock > 0 && !product.quantityInTheCart && <div className="col">
                                <button onClick={e => { e.preventDefault(); e.stopPropagation(); doAddToCart(product, 1); setShowCart(true); }} type="button" className="btn btn-outline-primary btn-sm">Agregar al Carro</button>
                              </div> }
                              { product.quantityInTheCart && <div className="col">
                                <button onClick={e => { e.preventDefault(); e.stopPropagation(); setShowCart(true); }} type="button" className="btn btn-outline-primary btn-sm"><Cart /> { product.quantityInTheCart }</button>
                              </div> }
                              { context && context.user && <div className="col">
                                <button onClick={e => { e.preventDefault(); e.stopPropagation(); navigate('/products-edit/' + product.id); }} type="button" className="btn btn-outline-primary btn-sm">Editar</button>
                              </div> }
                            </div>
                          </div>
                        </div>
                      </div>
                    })
                  }
              </div>
              </InfiniteScroll>
            </div> }

          </div>

        </div>
      </div>



      { isTotem() && keyboard && <div className="container fixed-bottom">
        <div className="row">
          <div className="col">
            <Keyboard
            display={customDisplay}
            layout={customLayout}

              onKeyPress={onKeyPress}
            />
          </div>
        </div>
      </div> }
    </>
  );
}

export default Catalog;
