import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './Catalog.module.scss';

const StillHereModal = (props) => {
  const [remaining, setRemaining] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(props.getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  });


  return (
    <Modal show={props.show} onHide={props.handleIddleClose} size="lg" className={ `${styles.modal}`  }>
      <Modal.Header closeButton>
        <Modal.Title>Aún estas aca?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Necesitas más tiempo para terminar tu compra?</p>
        <p>Segundos hasta terminar tu sesión: { remaining }</p>
      </Modal.Body>
      <Modal.Footer>

        <button onClick={e => { props.handleIddleWait(); props.handleIddleClose(); }} type="button" className="btn btn-outline-primary btn-sm p-2">Esperar</button>

      </Modal.Footer>
    </Modal>
  );
};

export default StillHereModal;