import Modal from 'react-bootstrap/Modal';
import styles from './Catalog.module.scss';
import useApi from '../../hooks/UseApi';
import { useEffect, useState } from 'react';
import IncDecCounter from '../../components/IncDecCounter';
import { Cart } from 'react-bootstrap-icons';
import useAuthentication from '../../auth/useAuthentication';

const DetailModal = (props) => {

  const { get } = useApi();
  const [ product, setProduct ] = useState();
  const context = useAuthentication();

  const getData = () => {
    if (props.product && props.product.id) {
      get('products/' + props.product.id).then(r => {
        setProduct(r.data)
      });
    }
  };
  useEffect(getData, [ props.product ]);
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg" className={ `${styles.modal}`  }>
      <Modal.Header closeButton>
        <Modal.Title>{props.product?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-lg-4 col-sm-12' pull="12">
            <div className='row'>
            <div className='col-12'>
              <span className='fw-bold'>Código:</span> { props.product?.code }
              {/* <span className='fw-bold'>Código:</span> { props.product?.code.replace(context.user.selectedMachine.id, '') } */}
            </div>
            <div className='col-12'>
              <span className='fw-bold'>Disponible:</span> { props.product?.stock }
            </div>
            { product && product.filters_products.map( e => {
                return <div key={e.id} className='col-12'>
                  <span className='fw-bold'>{ e.filters_value.filter.group }:</span> { e.filters_value.value }
                </div>
            })}
            </div>
          </div>
          <div className='col-lg-8 col-sm-12' push="12">
            <div className={ `${styles.image_container}` }>
              <img src={ props.product?.image } alt={props.product?.name} className={ `${styles.image}` } />
            </div>
          </div>

        </div>
        <p className={ `${styles.crop} card-text` }>{ props.product?.description }</p>
      </Modal.Body>
      <Modal.Footer>
        <div className='row text-center'>
          {/* <div className='col-6'>
            <IncDecCounter updateQuantity={props.doAddToCart} item={props.product} />
          </div> */}
          <div className='col'>
          { (!props.product || (props.product && props.product.stock > 0 && !props.product.quantityInTheCart)) && <div className="col">
            <button onClick={e => { e.preventDefault(); props.doAddToCart(product, 1); props.handleClose(); }} type="button" className="btn btn-outline-primary">Agregar al Carro</button>
          </div> }
          { props.product && props.product.quantityInTheCart && <div className="col">
            <button onClick={e => { props.setShowCart(true); props.handleClose(); }} type="button" className="btn btn-outline-primary btn-sm p-2"><Cart /> Agregado al Carro { product?.quantityInTheCart }</button>
          </div> }

            {/* <Button variant="primary" onClick={props.handleClose}>Agregar al carro</Button> */}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DetailModal;