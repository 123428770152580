import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Config from '../config/config';
import axios from 'axios';
import { toast } from 'react-toastify';
import useAuthentication from '../auth/useAuthentication';

// https://www.newline.co/@3nvi/centralizing-api-error-handling-in-react-apps--80296494
const useApi = () => {

  const context = useAuthentication();
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (context.user) {
      axios.defaults.headers.common['x-access-token'] = context.user.token;
      axios.defaults.headers.common['x-machine-id'] = context.user.selectedMachine.id;
    } else {
      axios.defaults.headers.common['x-access-token'] = null;
      axios.defaults.headers.common['x-machine-id'] = Config.MACHINE_ID;
    }
  }, [context.user]);

  const showError = (err) => {
    if (err && err.response && err.response.data) {
      if (err.response.data.message) {
        toast.error(<div>{ err.response.data.message }</div>);
      } else if(err.response.data.code) {
        toast.error(<div>{ err.response.data.code }</div>);
      } else {
        toast.error(<div>Error Desconocido</div>);
      }
    } else {
      toast.error(JSON.stringify(err));
    }
  };

  let get = (path, extraOptions) => {
    return new Promise((resolve, reject) => {
      setUpdating(true);

      if (extraOptions && extraOptions.page) {
        let page = extraOptions.page;
        if (path.indexOf('?') >= 0) {
          path = path + '&_page=' + page;
        } else {
          path = path + '?_page=' + page;
        }
        extraOptions = _.omit(extraOptions, 'page');
      }

      if (extraOptions && extraOptions.perPage) {
        let perPage = extraOptions.perPage;
        if (path.indexOf('?') >= 0) {
          path = path + '&_perPage=' + perPage;
        } else {
          path = path + '?_perPage=' + perPage;
        }
        extraOptions = _.omit(extraOptions, 'perPage');
      }

      if (extraOptions && extraOptions.filters) {
        let filters = JSON.stringify(extraOptions.filters);
        if (path.indexOf('?') >= 0) {
          path = path + '&_filters=' + filters;
        } else {
          path = path + '?_filters=' + filters;
        }
        extraOptions = _.omit(extraOptions, 'filters');
      }


      let options = {
        method: 'GET',
        url: Config.API.BASE_URI + encodeURI(path)
      };
      if (extraOptions) {
        options = {...options, ...extraOptions};
      }
      axios(options).then(response => {
        resolve(response);
        setUpdating(false);
      }).catch(err => {
        console.log('err', err)
        if (err.code == 'ERR_NETWORK') {
          console.log(err);
        } else if (err.response && err.response.status) {
          if (err.response.status === 401) {
            // authContext.logout();
            showError(err);
            // reject(err.response);
            setUpdating(false);
          } else if (err.response.status === 402) {
            // authContext.enable2Fa();
            showError(err);
            // reject(err.response);
            setUpdating(false);
          }
        } else {
          showError(err);
          reject(err.response);
          setUpdating(false);
        }
      });
    });
  };

  let post = (path, data) => {
    return new Promise((resolve, reject) => {
      setUpdating(true);
      axios({
        method: 'POST',
        data: data,
        url: Config.API.BASE_URI + path,
      }).then(response => {
        resolve(response);
        setUpdating(false);
      }).catch(err => {
        console.log(err);
        if (err.response && err.response.status) {
          if (err.response.status === 401) {

            // if (err.response.data && err.response.data.code && err.response.data.code == 'TOKEN-EXPIRED') {
            //   let token = JSON.parse(localStorage.getItem(Config.AUTH.LOCAL_STORAGE_KEY) || '{}');
            // } else {
            //   // authContext.logout();
            //   showError(err);
            //   reject(err.response);
            //   setUpdating(false);
            // }
          } else if (err.response.status === 402) {
            // authContext.enable2Fa();
          }
        }
        // showError(err);
        showError(err);
        reject(err.response);
        setUpdating(false);
      });
    });
  };


  let del = (path) => {
    return new Promise((resolve, reject) => {
      setUpdating(true);
      axios({
        method: 'DELETE',
        url: Config.API.BASE_URI + path,
      }).then(response => {
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        if (err.response && err.response.status) {
          if (err.response.status === 401) {
            // authContext.logout();
          } else if (err.response.status === 402) {
            // authContext.enable2Fa();
          }
        }
        showError(err);
        reject(err.response);
        setUpdating(false);

      }).finally(() => {
        setUpdating(false);
      });
    });
  };



  return { updating : updating, get : get, post : post, del : del, showError };
};
export default useApi;