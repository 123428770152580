import React, { useCallback, useEffect, useState } from 'react';
import Spinner from '../../shared/Spinner';
import Paginator from '../../shared/Paginator';
import { Link, useNavigate } from 'react-router-dom';
import { confirm } from "../../shared/Confirmation";
import { Button, Form } from 'react-bootstrap';
import Config from '../../config/config';
import moment from 'moment';
import useApi from '../../hooks/UseApi';
import { Trash, PencilSquare, PlusLg } from 'react-bootstrap-icons';
import { FormGroup } from 'react-bootstrap';
// var DatePicker = require("react-bootstrap-date-picker");
// import BootstrapDatePickerComponent from '../../components/BootstrapDatePickerComponent'
// import DateTimeField from "react-bootstrap-datetimepicker"
import { read, utils, writeFile } from 'xlsx';
import useAuthentication from '../../auth/useAuthentication';




const Stocks = (props) => {

  const [ fromDate, setFromDate ] = useState(moment().format('YYYY-MM-DD'));
  const [ toDate, setToDate ] = useState(moment().format('YYYY-MM-DD'));
  const [ data, setData ] = useState();
  const { get, del, updating } = useApi();
  const [ page, setPage ] = useState(1);
  const [ total, setTotal ] = useState(0);
  const [ search, setSearch ] = useState('');
  const navigate = useNavigate();
  const context = useAuthentication();


  const getData = () => {
    get('products/data/' + moment(fromDate).format('YYYY-MM-DD') + '/' + moment(toDate).format('YYYY-MM-DD')).then(r => {
      r.data.forEach(e => {
        if (e.machine.type === 'VENDING' && e.slot) {
          e.max_stock = e.slot_capacity * e.slot.split(',').length;
        }
      });
      setData(r.data);
    });
  };
  // useEffect(getData, [ context.user.selectedMachine.id ]);


  const doSync = () => {
    get('products/sync/all').then(r => {
      getData();
    });
  };


  const t = (text) => {
    return text;
  };




  /* Callback invoked when the button is clicked */
  const xport = useCallback(async () => {
    /* Create worksheet from HTML DOM TABLE */
    const table = document.getElementById("Table2XLSX");
    const wb = utils.table_to_book(table);

    /* Export to file (start a download) */
    writeFile(wb, "SheetJSTable.xlsx");
  });


  return (
    <>

      <div className="card">
        <div className="card-body">
          <br/>
          <div className="row">
            {/* <div className="col-2">
              <Form.Group controlId="dob">
                <Form.Label>Fecha Desde</Form.Label>
                <Form.Control type="date"
                value={fromDate}
                defaultValue={fromDate}
                onChange={(e) => setFromDate(e.target.value)}/>
              </Form.Group>
            </div>
            <div className="col-2">
              <Form.Group controlId="dob">
                <Form.Label>Fecha Hasta</Form.Label>
                <Form.Control type="date"
                value={toDate}
                defaultValue={toDate}
                onChange={(e) => setToDate(e.target.value)}/>
              </Form.Group>
            </div> */}

            <div className="col-6 mt-4">
              {/* <Button onClick={e => handleSubmit(e)} variant="primary" type="button" className="mt-2 me-4">Mostrar</Button> */}
              { !updating && !data && <Button onClick={doSync} variant="primary" type="button" className="mt-2">Generar</Button> }
              { updating && <Button variant="primary" type="button" className="mt-2" disabled>
                  <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  <span role="status">&nbsp;Generando...</span>
              </Button> }

              { data && data.length > 0 && <Button onClick={xport} variant="primary" type="button" className="mt-2">Exportar</Button> }
            </div>

          </div>
        </div>
      </div>


      { data && <div className="card">
        <div className="card-body">
          <table id="Table2XLSX" className='table table-bordered table-striped'>
            <thead class="table-light">
              <tr>
                <th>Maquina</th>
                <th>Código</th>
                <th>Producto</th>
                <th>Stock Actual</th>
                <th>Stock Mínimo</th>
                <th>Stock Máximo</th>
                <th>Stock sugerido para reposición</th>
                <th>Slot</th>
                {/* <th>Cap. Slot</th> */}
              </tr>
            </thead>
            <tbody className='align-middle'>
          { data.map(product => {
            return <tr key={product.machine.id + '-' + product.id}>
                <td>{product.machine.alias}</td>
                <td>{product.code}</td>
                <td>{product.name}</td>
                <td>{product.stock}</td>
                <td>{product.min_stock}</td>
                <td>{product.max_stock && product.max_stock > 0 ? product.max_stock : ''}</td>
                <td>{product.max_stock > 0 ? product.max_stock - product.stock : ''}</td>
                <td>{product.slot}</td>
                {/* <td>{product.slot_capacity}</td> */}
              </tr>
            }) }
          </tbody>
          </table>
        </div>
      </div> }

  </>);
}

export default Stocks;
