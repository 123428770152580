import { useEffect, useState } from "react";

const IncDecCounter = (props) => {
  const [num, setNum]= useState(props.item.quantityInTheCart);
  useEffect(() => {

    if (num != props.item.quantity) {
      props.updateQuantity(props.item, num);
    }
  }, [ num ]);

  const incNum = () => {
    if (!num) {
      setNum(1);
    } else {
      if (props.item.stock > Number(num)) {
        setNum(Number(num) + 1);
      }
    }
  };
  const decNum = () => {
    if (num && num > 1) {
      setNum(num - 1);
    }
  };
  const handleChange = (e) => {
   setNum(e.target.value);
  };

   return(
    <div className="input-group flex-nowrap mb-3">
      <button onClick={e => decNum()} role="button" className="btn btn-outline-primary" type="button" id="button-addon1">-</button>
      <input type="text" className="form-control text-center" value={num} onChange={e => handleChange(e)} />
      <button onClick={e => incNum()} role="button" className="btn btn-outline-primary" type="button" id="button-addon1">+</button>
    </div>
  );
}

export default IncDecCounter;