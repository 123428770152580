import React, { useEffect, useState } from 'react';
import Spinner from '../../shared/Spinner';
import Paginator from '../../shared/Paginator';
import { Link, useNavigate } from 'react-router-dom';
import { confirm } from "../../shared/Confirmation";
import Config from '../../config/config';
import moment from 'moment';
import useApi from '../../hooks/UseApi';
import { Trash, PencilSquare, PlusLg } from 'react-bootstrap-icons';
import useAuthentication from '../../auth/useAuthentication';

const Rows = (props) => {
  return (
    <>
      {props.data.map((item) => {
        return <>
          <tr onClick={ (e) => { props.rowActionHandler(e, 'view', item) } } key={item.id}>
            {/* <td className='text-nowrap'>
              <a href="#!" onClick={(e) => props.rowActionHandler(e, 'delete', item) }><Trash /></a>
              <a className="ml-2" href="#!" onClick={(e) => props.rowActionHandler(e, 'edit', item) }><PencilSquare /></a>
            </td> */}
            <td>{ item.machine }</td>
            <td>{ moment(item.date).format('DD/MM/YYYY') }</td>
            <td>$ { item.total }</td>
            <td>{ item.status }</td>
            {/* <td className="text-truncate" style={{ maxWidth: '300px'}}>{ item.filters_values && item.filters_values.map(e => e.value).join(', ') }</td> */}
          </tr>

          <tr><td colSpan="4"><table className='table table-hover table-striped'><th>Producto</th><th>Precio</th><th>Estado</th>
          {item.orders_details.map((detail) => {
            return <tr>
              <td>{ detail.product.name }</td>
              <td>$ { detail.price }</td>
              <td>{ detail.status }</td>
            </tr>})}
          </table></td></tr>

      </>})}
    </>
  )
};

const Orders = (props) => {

  const [ data, setData ] = useState();
  const { get, del, updating } = useApi();
  const [ page, setPage ] = useState(1);
  const [ total, setTotal ] = useState(0);
  const [ search, setSearch ] = useState('');
  const navigate = useNavigate();
  const context = useAuthentication();

  const rowActionHandler = async (e, action, item) => {
    e.preventDefault();
    e.stopPropagation();
    if (action === 'edit') {
      navigate('/filters-edit/' + item.id);
    } else if (action === 'delete') {
      var result = await confirm("Está seguro de eliminar el registro?");
      if (result) {
        del('orders/' + item.id).then(() => {
          getData();
        }).catch(err => {
          console.log(err)
        });
      }
    }
  };

  // const handleClose = () => setShow(false);


  const getData = () => {
    let options = { page : page };
    get('orders', options).then(r => {
      console.log(r.data);
      setTotal(parseInt(r.headers['x-paging-total-pages']));
      setData(r.data);
    });
  };
  useEffect(getData, [ props.location, search, page, context.user.selectedMachine.id ]);


  const onPageChange = (page) => {
    setPage(page);
  };


  const clearSearchHandler = () => {
    setPage(1);
    setSearch('');
  };

  const searchHandler = (search) => {
    setSearch(search);
  };

  const t = (text) => {
    return text;
  };


  return (


      <div className="card">
        <div className="card-body">
          <br/>
          <div className="row">
            <div className="col-8">
              {/* <Search search={search} setSearch={setSearch} searchHandler={searchHandler} clearSearchHandler={clearSearchHandler} /> */}
            </div>
            <div className="col-4 text-end">
              <Link className="btn btn-primary btn-sm" to={ '/filters-edit' }><PlusLg className='me-1'/>Nuevo</Link>

              {/* <button onClick={(e) => { newHandler(e); } } className="btn btn-primary btn-sm">{t('Common.New')}</button> */}
              {/* <button className="ml-3 mr-3 btn btn-danger btn-sm">{t('Common.Delete')}</button> */}
              {/* <button onClick={(e) => { deleteHandler(e); } } className="ml-3 mr-3 btn btn-danger btn-sm">Delete</button> */}
            </div>
          </div>

          { (!data || updating) ? <Spinner /> :
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  {/* <th></th> */}
                  <th>Máquina</th>
                  <th>Fecha</th>
                  <th>Total</th>
                  <th>Estado</th>
                </tr>
              </thead>

              <tbody><Rows rowActionHandler={rowActionHandler} data={data} /></tbody>

            </table>



          }

          { total > 0 && <div className="d-flex justify-content-end">
            <Paginator currentPage={page} totalPages={total} onChange={onPageChange} />
          </div> }

        </div>
      </div>

  );
}

export default Orders;
