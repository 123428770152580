const getSubdomain = url => {
  let domain = url;
  if (url.includes("://")) {
    domain = url.split('://')[1];
  }
  const subdomain = domain.split('.')[0].toLowerCase();
  let machineId;

  const subdomains = {
    'device-totem-01':	'1000000001',
    'totem-01':	'1000000001',
    'device-totem-02':	'1000000002',
    'totem-02':	'1000000002',
    'device-totem-03':	'1000000003',
    'totem-03':	'1000000003',
    'device-totem-04':	'1000000004',
    'totem-04':	'1000000004',
    'device-totem-05':	'1000000005',
    'totem-05':	'1000000005',
    'device-totem-06':	'1000000006',
    'totem-06':	'1000000006',
    'device-totem-07':	'1000000007',
    'totem-07':	'1000000007',
    'device-totem-08':	'1000000008',
    'totem-08':	'1000000008',
    'device-totem-09':	'1000000009',
    'totem-09':	'1000000009',
    'device-totem-10':	'1000000010',
    'totem-10':	'1000000010',
    'device-totem-11':	'1000000011',
    'totem-11':	'1000000011',
    'device-totem-12':	'1000000012',
    'totem-12':	'1000000012',
    'device-totem-13':	'1000000013',
    'totem-13':	'1000000013',
    'device-totem-14':	'1000000014',
    'totem-14':	'1000000014',
    'device-totem-15':	'1000000015',
    'totem-15':	'1000000015',
    'device-totem-16':	'1000000016',
    'totem-16':	'1000000016',
    'device-totem-17':	'1000000017',
    'totem-17':	'1000000017',
    'device-totem-18':	'1000000018',
    'totem-18':	'1000000018',
    'device-totem-19':	'1000000019',
    'totem-19':	'1000000019',
    'device-totem-20':	'1000000020',
    'totem-20':	'1000000020',
    'device-totem-21':	'1000000021',
    'totem-21':	'1000000021',
    'device-totem-22':	'1000000022',
    'totem-22':	'1000000022',
    'device-totem-23':	'1000000023',
    'totem-23':	'1000000023',
    'device-totem-24':	'1000000024',
    'totem-24':	'1000000024',
    'device-totem-25':	'1000000025',
    'totem-25':	'1000000025',
    'device-totem-26':	'1000000026',
    'totem-26':	'1000000026',
    'device-totem-27':	'1000000027',
    'totem-27':	'1000000027',
    'device-totem-28':	'1000000028',
    'totem-28':	'1000000028',
    'device-totem-29':	'1000000029',
    'totem-29':	'1000000029',
    'device-totem-30':	'1000000030',
    'totem-30':	'1000000030',
  };

  if (subdomains[subdomain]) {
    machineId = subdomains[subdomain];
  }

  // // "domain": "device-totem-01.winfas.com.ar",
  // if (subdomain == 'device-vinos' || subdomain == 'vinos') {
  //   machineId = '1111111111';
  // } else if (subdomain == 'device-bebidas' || subdomain == 'bebidas') {
  //   machineId = '2209230099';
  // } else if (subdomain == 'device-helados' || subdomain == 'helados') {
  //   machineId = '2209270151';
  // } else if (subdomain == 'device-medicamentos' || subdomain == 'medicamentos') {
  //   machineId = '2209270151';
  // }
  return { subdomain, machineId };
};


const { subdomain, machineId } = getSubdomain(window.location.host);
const local = {
  API: {
    BASE_URI : 'http://localhost:8080/'
    // BASE_URI : 'http://api.infas.com:8080/'
    // BASE_URI : 'http://' + getSubdomain(window.location.host) + '.infas.com:8080/'
    // BASE_URI : 'http://' + subdomain + '.infas.com:8080/'
    // BASE_URI : 'http://192.168.1.10:8080/'
    // BASE_URI : 'https://vinos.winfas.com.ar/'

    // BASE_URI : 'https://api.winfas.com.ar/' //esta es prod

  },
  // MACHINE_ID : process.env.REACT_APP_MACHINE_ID
  MACHINE_ID : '1111111111'
  // MACHINE_ID : machineId
};

const prod = {
  API: {
    // BASE_URI : 'https://vinos.winfas.com.ar/'
    BASE_URI : 'https://api.winfas.com.ar/'
    // BASE_URI : 'https://' + getSubdomain(window.location.host) + '.winfas.com.ar/'
    // BASE_URI : 'https://' + subdomain + '.winfas.com.ar/'
    // BASE_URI : 'http://' + getSubdomain(window.location.host) + '.infas.com:8080/'
  },
  // MACHINE_ID : '1111111111'
  MACHINE_ID : machineId
  // MACHINE_ID : process.env.REACT_APP_MACHINE_ID
};


// alert(prod.API.BASE_URI)
// alert('machgine: ' + process.env.REACT_APP_MACHINE_ALIAS)
// alert('machgine: ' + process.env.REACT_APP_MACHINE_ID)
// alert('stage: ' + process.env.REACT_APP_STAGE)
const config = process.env.REACT_APP_STAGE === 'prod' ? prod : local;
export default {
  ...config
};