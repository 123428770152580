import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { AuthContext } from "./AuthContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import Config from '../config/config';


// https://dev.to/joseprest/the-advanced-guide-to-react-context-with-hooks-dh9

export function AuthProvider({ children }) {

  const USER_KEY = 'USER-KEY';
  const [ user, setUser ] = useState(null);
  const { getItem, setItem } = useLocalStorage();

  useEffect(() => {
    const user = getItem(USER_KEY);
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);


  return (
    <AuthContext.Provider
      value={{
        login: (data) => {
          return new Promise((resolve, reject) => {
            axios({
              method: 'POST',
              url: Config.API.BASE_URI + 'users/login',
              data: data
            }).then(response => {
              response.data.selectedMachine = response.data.machines[0];
              setItem(USER_KEY, JSON.stringify(response.data));
              setUser(response.data);
              resolve();
            }).catch( err => {
              console.log(err);
              reject();
            });
          });
        },

        setMachine: (machineId) => {
          const selectedMachine = user.machines.filter(machine => { return machine.id == machineId } );
          // console.log(selectedMachine)
          const newUserData = { ...user, ...{selectedMachine: selectedMachine.pop()} };
          setItem(USER_KEY, JSON.stringify(newUserData));
          setUser(newUserData);
        },

        logout: () => {
          return new Promise((resolve, reject) => {
            setItem(USER_KEY, null);
            setUser(null);
            resolve();
          });
        },

        register: (data) => {
          return new Promise((resolve, reject) => {
            axios({
              method: 'POST',
              url: Config.API.BASE_URI + 'users/register',
              data: data
            }).then(response => {
              setItem(USER_KEY, JSON.stringify(response.data));
              setUser(response.data);
              toast.success(<div>El usuario se registró correctamente</div>);
              resolve();
            }).catch( err => {
              toast.error(<div>{ err.response.data.message }</div>);
              reject();
            });
          });

        },
        isAuthenticated: false,
        user: user
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}