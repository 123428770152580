import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom"
import useAuthentication from "../auth/useAuthentication";
// import logoCr from '../assets/logo_cr.svg'; // with import
import logo from '../assets/logo.png';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import styles from './MainHeader.module.scss';
import { ArrowRightSquare } from 'react-bootstrap-icons';


export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false

  const location = useLocation()
  const context = useAuthentication();
  const navigate = useNavigate();


  const isTotem = () => {
    try {
      const url = window.location.host;
      let domain = url;
      if (url.includes("://")) {
        domain = url.split('://')[1];
      }
      const subdomain = domain.split('.')[0];
      return subdomain.indexOf('device-'.toLowerCase()) > -1;
    } catch(err) {
      return false;
    }
  };


  const goToHome = () => {

    if (isTotem()) {
      return;
    }

    if (location.pathname  === '/') {
      navigate('/products');
    } else {
      navigate('/');
    }
  }

  return (
    <Navbar expand="lg" className="bg-body-tertiary mb-2">
      <Container>
        <div className=''>
          <Navbar.Brand onClick={e => goToHome()} role="button">
            <img style={{ width: "25%"}} src={logo} />
          </Navbar.Brand>
          { context && context.user && <Navbar.Toggle aria-controls="basic-navbar-nav" className='float-end' /> }
        </div>
        { context && context.user && <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
          <Nav className="">
            <NavDropdown title={`Máquina ` + context.user?.selectedMachine?.alias} id="basic-nav-dropdown">
            {
              context.user?.machines?.map(machine => {
                if (machine && machine.id) {
                  return <NavDropdown.Item key={machine} onClick={ e => context.setMachine(machine.id) }>{machine.alias} ({machine.type.toLowerCase()})</NavDropdown.Item>
                }
              })
            }
            </NavDropdown>
          </Nav>


        { context && context.user &&
          <Link onClick={() => { setIsNavOpen((prev) => !prev ); context.logout(); }} className={styles.username}>{context.user.username}
            <ArrowRightSquare />
          </Link>
        }
        {/* { context && !context.user && <li className="mt-5">
          <Link onClick={() => { setIsNavOpen((prev) => !prev ); }} className='no-underline text-black font-saira uppercase' to='/login'>Ingresar</Link>
        </li> } */}

        </Navbar.Collapse> }
      </Container>
    </Navbar>
  );
}