import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useAuthentication from "../../auth/useAuthentication"; //** added */
import { toast } from 'react-toastify';


const LoginPage = () => {

  const context = useAuthentication();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    context.login({email, password}).then(() => {
      navigate('/products');
    }).catch(err => {
      toast.error(<div>Usuario y/o contraseña incorrectos</div>);
    });
  };

  return (
    <div className="row">
      <div className="col-4 offset-4">
        <form onSubmit={handleSubmit} noValidate="novalidate">
          <div className="mb-3">
            <label>Usuario</label>
            <input
              onChange={e => setEmail(e.target.value)}
              type="email"
              className="form-control"
              placeholder="Enter email"
            />
          </div>
          <div className="mb-3">
            <label>Clave</label>
            <input
              onChange={e => setPassword(e.target.value)}
              type="password"
              className="form-control"
              placeholder="Enter password"
            />
          </div>
          {/* <div className="mb-3">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck1"
              />
              <label className="custom-control-label" htmlFor="customCheck1">
                Remember me
              </label>
            </div>
          </div> */}
          <div className="d-grid gap-2 col-12 mx-auto">
            <button type="submit" className="btn btn-primary">
              Ingresar
            </button>
            <button onClick={e => navigate(-1)} type="button" className="btn btn-secondary">
              Cancelar
            </button>
          </div>
          {/* <p className="forgot-password text-right">
            Forgot <a href="#">password?</a>
          </p> */}
        </form>
      </div>
    </div>
  )
}

export default LoginPage;