import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import imgRegistro from '../../assets/img_registro.svg'; // with import
import useAuthentication from "../../auth/useAuthentication"; //** added */
import { toast } from 'react-toastify';

const RegisterPage = () => {

  const context = useAuthentication();
  const navigate = useNavigate();
  // const [email, setEmail] = useState('juancarlosfelman@gmail.com');
  // const [emailError, setEmailError] = useState('');
  // const [password, setPassword] = useState('hunter');
  // const [passwordError, setPasswordError] = useState('');
  // const [passwordRepeat, setPasswordRepeat] = useState('hunter');
  // const [passwordRepeatError, setPasswordRepeatError] = useState('');
  // const [username, setUsername] = useState('JCFelman');
  // const [usernameError, setUsernameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordRepeatError, setPasswordRepeatError] = useState('');
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    setEmailError('');
    setUsernameError('');
    setPasswordError('');
    setPasswordRepeatError('');
    let error = false;

    if (!email) {
      setEmailError('Debe ingresar el correo electrónico');
      error = true;
    } else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) {
      setEmailError('El correo electrónico es inválido');
      error = true;
    }

    if (!username) {
      setUsernameError('Debe ingresar el nombre de usuario');
      error = true;
    } else if (username.length < 3) {
      setUsernameError('El nombre de usuario es inválido');
      error = true;
    }

    if (!password) {
      setUsernameError('Debe ingresar la contraseña');
      error = true;
    } else if (password.length < 6) {
      setUsernameError('La contraseña debe tener al menos 6 caracteres');
      error = true;
    }

    if (!password) {
      setPasswordError('Debe ingresar la contraseña');
      error = true;
    } else if (password.length < 6) {
      setPasswordError('La contraseña debe tener al menos 6 caracteres');
      error = true;
    }

    if (!passwordRepeat) {
      setPasswordRepeatError('Debe repetir la contraseña');
      error = true;
    } else if (password != passwordRepeat) {
      console.log(password , passwordRepeat)
      setPasswordRepeatError('Las contraseñas no coinciden');
      error = true;
    }

    if (!error) {
      const data = {
        email, password, username
      };
      context.register(data).then(() => {
        navigate("/home");
      }).catch(err => {
        toast.error(<div>Ocurrió un error. Intente nuevamente</div>);
      });

    }
  };

  return (
    <>
      <div className='border-t border-black mt-2 mb-4'>
        <div className='max-w-[1080px] mx-auto'>
          <div className='flex w-full py-2 justify-between items-center py-4 flex-nowrap'>
            <div className='flex flex-col basis-full lg:basis-1/2 xs:text-center lg:text-left items-center lg:items-start'>
              <p className='font-alpha w-[80%] hidden lg:block'>¡En breve estarás disfrutando y poniendo a prueba tu ingenio y connocimientos!</p>
              <p className='py-3 text-3xl font-alpha'>Registro de usuario</p>

              {/* <img src={imgRegistro} className="w-[50%] lg:hidden" alt="" /> */}

              <form onSubmit={handleSubmit} noValidate="novalidate" autoComplete="off" className='flex flex-col w-full justify-betwwen p-2'>
                <div className='pt-5'>
                  <p className='pb-1 m-0 font-bold font-saira uppercase'>Correo electrónico</p>
                  <input onChange={e => setEmail(e.target.value)} type="email" className='rounded-2xl bg-lightblue p-2 border border-cr w-full lg:w-[80%]'></input>
                  { emailError && <p className='pb-1 m-0 text-error font-bold font-saira'>{ emailError }</p> }
                </div>
                <div className='mt-4'>
                  <p className='pb-1 m-0 font-bold font-saira uppercase'>Nombre de usuario</p>
                  <input onChange={e => setUsername(e.target.value)} type="email" className='rounded-2xl bg-lightblue p-2 border border-cr w-full lg:w-[80%]'></input>
                  { usernameError && <p className='pb-1 m-0 text-error font-bold font-saira'>{ usernameError }</p> }
                </div>
                <div className='mt-4'>
                  <p className='pb-1 m-0 font-bold font-saira uppercase'>Contraseña</p>
                  <input onChange={e => setPassword(e.target.value)} role="presentation" autoComplete="new-password" type="password" className='rounded-2xl bg-lightblue p-2 border border-cr w-full lg:w-[80%]'></input>
                  { passwordError && <p className='pb-1 m-0 text-error font-bold font-saira'>{ passwordError }</p> }
                </div>
                <div className='mt-4'>
                  <p className='pb-1 m-0 font-bold font-saira uppercase'>Repetir Contraseña</p>
                  <input onChange={e => setPasswordRepeat(e.target.value)} type="password" className='rounded-2xl bg-lightblue p-2 border border-cr w-full lg:w-[80%]'></input>
                  { passwordRepeatError && <p className='pb-1 m-0 text-error font-bold font-saira'>{ passwordRepeatError }</p> }
                </div>

                <div className='mt-5 lg:w-[60%] w-[100%] px-10 lg:px-0'>
                  <div onClick={handleSubmit} className='mt-5 bg-cr text-white rounded-2xl p-2 text-center uppercase cursor-pointer'>Registrarse</div>
                  <div className='mt-3 border border-black text-black rounded-2xl p-2 text-center uppercase cursor-pointer'>
                    <Link className="no-underline text-black" to='/login'>Regresar</Link>
                  </div>
                </div>
              </form>

            </div>
            <div className='lg:flex basis-1/2 items-center justify-center hidden'>
              {/* <img src={imgRegistro} className="w-[70%]" alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterPage;