import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import './index.scss';


import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";


import Register from "./pages/Register/Register";
import Login from "./pages/Login/Login";
import Catalog from "./pages/Catalog/Index";
import Products from "./pages/Products/Index";
import ProductsEdit from "./pages/Products/Edit";
import Users from "./pages/Users/Index";
import UsersEdit from "./pages/Users/Edit";
import Filters from "./pages/Filters/Index";
import Orders from "./pages/Orders/Index";
import FiltersEdit from "./pages/Filters/Edit";
import Data from "./pages/Data/Index";
import Stocks from "./pages/Stocks/Index";

// import MainHeader from "./components/MainHeader";

import Root from "./App";

import { AuthProvider } from "./auth/AuthProvider";

// You can do this:
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} >
      <Route path="" element={<Catalog />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="orders" element={<Orders />} />
      <Route path="data" element={<Data />} />
      <Route path="stocks" element={<Stocks />} />
      <Route path="products" element={<Products />} />
      <Route path="products-edit/:id?" element={<ProductsEdit />} />
      <Route path="users" element={<Users />} />
      <Route path="users-edit/:id?" element={<UsersEdit />} />
      <Route path="filters" element={<Filters />} />
      <Route path="filters-edit/:id?" element={<FiltersEdit />} />
    </Route>
  )
);

export default function App() {
  return <RouterProvider router={router} />;
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  // <StrictMode>
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
  // </StrictMode>
);