import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import useApi from '../../hooks/UseApi';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthentication from '../../auth/useAuthentication';


function GridComplexExample() {

  const navigate = useNavigate();
  const context = useAuthentication();
  const { get, post, updating, showError } = useApi();


  const [ validated, setValidated ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      get('users/' + id).then(r => {
        console.log(r)
        setEmail(r.data.email);
        setUsername(r.data.username);
      });
    }
  }, []);


  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };



  const handleSubmit = (event) => {
    event.preventDefault();

    // if (group == '' || (id && values.length == 0)) {
    //   setValidated(true);
    //   if (values.length == 0) {
    //     showError('Debe cargar al menos un valor');
    //   }
    // } else {

      const data = {
        id,
        email,
        username,
        password
      };

      post('users', data).then(r => {
        navigate(-1);
      }).catch(err => {
        console.log(err);
      });

  };

  return (
      <Form noValidate validated={validated}>


      <Row className="">
        <Col className='col-8'>

          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Email</Form.Label>
            <Form.Control required type="text" value={email} onChange={handleChange(setEmail)} />
            <div className="invalid-feedback">El email es requerido</div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Nombre</Form.Label>
            <Form.Control required type="text" value={username} onChange={handleChange(setUsername)} />
            <div className="invalid-feedback">El nombre es requerido</div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control required type="text" value={password} onChange={handleChange(setPassword)} />
          </Form.Group>

        </Col>
      </Row>




      <div className='d-flex justify-content-end'>
        <Button onClick={e => handleSubmit(e)} variant="primary" type="button" className="me-2">Guardar</Button>
        <Button onClick={e => navigate(-1)} variant="secondary">Cancelar</Button>
      </div>
    </Form>
  );
}

export default GridComplexExample;