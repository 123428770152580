import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainHeader from "./components/MainHeader";
import Sidebar from "./components/Sidebar";
import { useLocation, useParams } from 'react-router-dom';

const Root = () => {

  /**
   * Function converts path like /user/123 to /user/:id
   */
  const getRoutePath = (location, params) => {
    const { pathname } = location;

    if (!Object.keys(params).length) {
      return pathname; // we don't need to replace anything
    }

    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
      if (paramValue) {
        path = path.replace(paramValue, `:${paramName}`);
      }
    });
    return path;
  };

  const location = useLocation();
  const params = useParams();
  const path = getRoutePath(location, params);
  const sidebarPathsToExclude = [ '/', '/login' ];

  return (
    <div className="container-fluid">
      <ToastContainer />
        <div className="row flex-nowrap">
          <div className="col-12 bg-dark">
          <MainHeader />
          </div>
        </div>
        { sidebarPathsToExclude.indexOf(path) > -1 && <Outlet /> }
        { sidebarPathsToExclude.indexOf(path) == -1 && <>
        <div className="row flex-nowrap">
          <Sidebar />
          <div className="col py-3">
            <Outlet />
          </div>
        </div>
      </>}
    </div>
  );
};


export default Root;