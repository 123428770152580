import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import useApi from '../../hooks/UseApi';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthentication from '../../auth/useAuthentication';
import styles from './Products.module.scss';
import { XLg } from 'react-bootstrap-icons';

function GridComplexExample() {

  const navigate = useNavigate();
  const context = useAuthentication();
  const { get, post, updating, showError } = useApi();

  const [ options, setOptions ] = useState();
  const [ groups, setGroups ] = useState();

  const [ slot, setSlot ] = useState();
  const [ slotCapacity, setSlotCapacity ] = useState();

  const [ code, setCode ] = useState(0);
  const [ price, setPrice ] = useState(0);
  const [ stock, setStock ] = useState(0);
  const [ minStock, setMinStock ] = useState();
  const [ maxStock, setMaxStock ] = useState();
  const [ cost, setCost ] = useState(0);
  const [ margin, setMargin ] = useState(0);
  const [ name, setName ] = useState('');
  const [ image, setImage ] = useState('');
  const [ filters, setFilters ] = useState({});
  const [ description, setDescription ] = useState('');
  const [ file, setFile ] = useState();
  const { id } = useParams();

  useEffect(() => {

    if (id) {
      get('products/' + id).then(r => {
        const tmpFilters = { };
        r.data.filters_products.forEach(e => {
          tmpFilters[e.filters_value.filter_id] = e.filters_value.id;
        });
        setFilters(tmpFilters);
        setName(r.data.name);
        setImage(r.data.image);
        setDescription(r.data.description);
        setPrice(r.data.price);
        setMargin(r.data.margin);
        setStock(r.data.stock || 0);
        setMinStock(r.data.min_stock);

        setCost(r.data.cost);
        setCode(r.data.code.replace(context.user?.selectedMachine.id, ''));
        setSlot(r.data.slot);
        setSlotCapacity(r.data.slot_capacity);

        if (context.user?.selectedMachine.type === 'TOTEM') {
          setMaxStock(r.data.max_stock);
        } else if (slot) {
          setMaxStock(slotCapacity * slot.split(',').length);
        }

      });
    }

    get('filters/all/false').then(r => {
      const tmpGroups = [ ];
      const options = { };
      r.data.groups.forEach(e => {
        tmpGroups.push({ id: e.id, name: e.group });
        if (!options[e.group]) {
          options[e.id] = [ ];
        }
        options[e.id] = e.filters_values.map( v => { return { id: v.id, value: v.value }} );
      });
      setGroups(tmpGroups);
      setOptions(options);
    });
  }, []);

  useEffect(() => {
    if (margin) {
      setPrice((cost * 1) + (cost * margin / 100));
    }
  }, [cost]);

  useEffect(() => {
    if (slot && context.user?.selectedMachine.type === 'VENDING') {
      setMaxStock(slotCapacity * slot.split(',').length);
    }
  }, [slot, slotCapacity]);

  const clearSearchHandler = (group) => {
    const filtersClone = { ...filters };
    delete filtersClone[group];
    setFilters(filtersClone);
  };

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  const handleImageChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleOptionChange = (group, event) => {
    const tmpFilters = JSON.parse(JSON.stringify(filters));
    tmpFilters[group] = event.target.value;
    console.log(tmpFilters)
    setFilters(tmpFilters);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData() ;
    if (id) {
      data.append('id', id);
    }
    data.append('machine', context.user.selectedMachine.id);
    data.append('name', name);
    data.append('description', description);
    data.append('code', code);
    data.append('cost', cost);
    data.append('stock', stock);
    data.append('min_stock', minStock);
    data.append('max_stock', maxStock);
    data.append('margin', margin);
    data.append('price', price);
    data.append('slot', slot);
    data.append('slot_capacity', slotCapacity);

    if (file) {
      data.append('file', file);
    }
    data.append('filters', JSON.stringify(filters));

    post('products', data).then(r => {
      navigate(-1);
    }).catch(err => {
      console.log(err);
    });
  };

  const calculateMaxStock = (e) => {
    const value = e.target.value * 1;
    if (context.user?.selectedMachine.type === 'TOTEM') {
      setMaxStock(value);
    } else if (slot) {
      setMaxStock(slotCapacity * slot.split(',').length);
    }
  };

  const calculateValue = (e, w) => {
    const value = e.target.value * 1;
    if (cost && cost > 0 && w == 'margin') {
      setPrice((cost * 1) + (cost * value / 100));
      setMargin(value);
    } else if (margin && margin > 0 && w == 'cost') {
      setPrice((cost * 1) + (cost * value / 100));
      setCost(value);
    } else if (w == 'cost') {
      setCost(value);
    } else if (w == 'margin') {
      setMargin(value);
    }
  };


  return (
    <Form onSubmit={handleSubmit}>

      <Row className="">
        <Col className='col-8'>

          <Row className="">
            <Col className='col-3'>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Código</Form.Label>
                <Form.Control type="number" value={code} onChange={handleChange(setCode)} />
              </Form.Group>
            </Col>
            <Col className='col-3'>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Precio Compra</Form.Label>
                <Form.Control type="number" value={cost} onChange={e => calculateValue(e, 'cost')} className='text-end' />
              </Form.Group>
            </Col>
            <Col className='col-3'>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Margen</Form.Label>
                <Form.Control type="number" value={margin} onChange={e => calculateValue(e, 'margin')} className='text-end' />
              </Form.Group>
            </Col>
            <Col className='col-3'>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Precio Venta</Form.Label>
                <Form.Control type="number" value={price} readOnly={true} className='text-end' />
              </Form.Group>
            </Col>
          </Row>

          <Row className="">
            <Col className='col-6'>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Nombre</Form.Label>
                <Form.Control type="text" value={name} onChange={handleChange(setName)} />
              </Form.Group>
            </Col>
            <Col className='col-2'>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Stock Actual</Form.Label>
                <Form.Control type="text" readOnly={true} value={stock} onChange={handleChange(setStock)} className='text-end' />
              </Form.Group>
            </Col>
            <Col className='col-2'>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Stock Min</Form.Label>
                <Form.Control type="text" value={minStock} onChange={handleChange(setMinStock)} className='text-end' />
              </Form.Group>
            </Col>
            <Col className='col-2'>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Stock Max</Form.Label>
                <Form.Control type="text" readOnly={context.user?.selectedMachine.type === 'VENDING'} value={maxStock} onChange={e => calculateMaxStock(e)} className='text-end' />
              </Form.Group>
            </Col>
          </Row>

          <Row className="">
            <Col className='col-6'>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Slot</Form.Label>
                <Form.Control type="text" value={slot} onChange={handleChange(setSlot)} />
              </Form.Group>
            </Col>
            <Col className='col-6'>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Capacidad del Slot</Form.Label>
                <Form.Control type="text" value={slotCapacity} onChange={handleChange(setSlotCapacity)} className='text-end' />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Descripción</Form.Label>
            <Form.Control as="textarea" rows={3} value={description} onChange={handleChange(setDescription)} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Filtros</Form.Label>
            <Col className='col-12 px-2'>
              <div className={ `${styles.filtersx} row gap-2` }>
                { groups && groups.map(group => {
                  return <Col key={group.id} className={ `${styles.filters} col-12 rounded` }>
                    {group.name}
                    <button onClick={e => clearSearchHandler(group.id)} role="button" type="button" className="m-2 float-end btn btn-outline-primary"><XLg /></button>

                    { options[group.id] && options[group.id].map((e) => {
                      return <Form.Check
                        onChange={e => handleOptionChange(group.id, e)}
                        key={e.id}
                        id={e.id}
                        type="radio"
                        name={group.id}
                        value={e.id}
                        checked={filters[group.id] == e.id}
                        label={e.value} />
                    }) }
                  </Col>
                }) }
              </div>
            </Col>
          </Form.Group>
        </Col>

        <Col className='col-4'>
          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Imagen</Form.Label>
            {/* { !file && image && <img src={ Config.API.BASE_URI + 'images/' + image } className="img-fluid" /> } */}
            { !file && image && <img src={ image } className="img-fluid" /> }
            { file && <img src={URL.createObjectURL(file)} className="img-fluid mb-3" /> }
            <div>Formato Sugerido: 400x400 png</div>
            <Form.Control type="file" onChange={handleImageChange} />
          </Form.Group>
        </Col>
      </Row>


      {/* <Row>
        <Col className='col-12'>
          <Form.Label>Filtros</Form.Label>
        </Col>
        <Col className='col-12'>
          <div className={ `${styles.filtersx} row rxounded gap-2` }>
            { groups && groups.map(group => {
              return <Col key={group} className={ `${styles.filters} col-4 rounded` }>{group}
                { options[group].map((e) => {
                  return <Form.Check
                    onChange={e => handleOptionChange(group, e)}
                    key={e.id}
                    id={e.id}
                    type="radio"
                    name={group}
                    value={e.value}
                    checked={filters[group] == e.value}
                    label={e.value} />
                }) }
              </Col>
            }) }
          </div>
        </Col>
      </Row> */}


      <div className='d-flex justify-content-end'>
        { !updating && <>
          <Button variant="primary" type="submit" className="me-2">Guardar</Button>
          <Button onClick={e => navigate(-1)} variant="secondary">Cancelar</Button>
        </> }
        { updating && <button className="btn btn-primary" type="button" disabled>
          <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
          <span role="status">&nbsp;Guardando...</span>
        </button> }

      </div>
    </Form>
  );
}

export default GridComplexExample;