import React, { useCallback, useEffect, useState } from 'react';
import Spinner from '../../shared/Spinner';
import Paginator from '../../shared/Paginator';
import { Link, useNavigate } from 'react-router-dom';
import { confirm } from "../../shared/Confirmation";
import { Button, Form } from 'react-bootstrap';
import Config from '../../config/config';
import moment from 'moment';
import useApi from '../../hooks/UseApi';
import { Trash, PencilSquare, PlusLg } from 'react-bootstrap-icons';
import { FormGroup } from 'react-bootstrap';
// var DatePicker = require("react-bootstrap-date-picker");
// import BootstrapDatePickerComponent from '../../components/BootstrapDatePickerComponent'
// import DateTimeField from "react-bootstrap-datetimepicker"
import { read, utils, writeFile } from 'xlsx';
import useAuthentication from '../../auth/useAuthentication';




const Data = (props) => {

  // const [ fromDate, setFromDate ] = useState(moment().add(-100, 'days').format('YYYY-MM-DD'));
  const [ fromDate, setFromDate ] = useState(moment().format('YYYY-MM-DD'));
  const [ toDate, setToDate ] = useState(moment().format('YYYY-MM-DD'));
  const [ data, setData ] = useState();
  const { get, del, updating } = useApi();
  const [ page, setPage ] = useState(1);
  const [ total, setTotal ] = useState(0);
  const [ search, setSearch ] = useState('');
  const navigate = useNavigate();
  const context = useAuthentication();


  // useEffect(() => {
  //   alert(date)
  // }, [date]);

  const getData = () => {
    get('orders/data/' + moment(fromDate).format('YYYY-MM-DD') + '/' + moment(toDate).format('YYYY-MM-DD')).then(r => {
      // console.log(r)
      // console.log(r.data);
      const filters = { };
      r.data.forEach(o => {
        o.orders_details.forEach(od => {
          od.product.filters_products.forEach(filter => {
            filters[filter.filters_value.filter.group] = filter.filters_value.filter.group;
            od[filter.filters_value.filter.group] = filter.filters_value.value;
            // filters.push({
            //   [filter.filters_value.filter.group]: filter.filters_value.value
            // })
          });
        });
      });
      // console.log(filters)
      r.data.filters = Object.keys(filters);

      r.data.forEach(o => {
        o.orders_details.forEach(od => {
          r.data.filters.forEach(f => {
            if (!od[f]) {
              od[f] = '';
            }
          });
        });
      });

      r.data.filters = Object.keys(filters);
      console.log(r.data);
      setData(r.data);
    });
  };
  useEffect(getData, [ context.user.selectedMachine.id ]);


  const onPageChange = (page) => {
    setPage(page);
  };


  const clearSearchHandler = () => {
    setPage(1);
    setSearch('');
  };

  const searchHandler = (search) => {
    setSearch(search);
  };

  const t = (text) => {
    return text;
  };


  const handleSubmit = (event) => {
    event.preventDefault();

    // const options = { fromDate, toDate };
    // get('orders/data/' + moment(fromDate).format('YYYY-MM-DD') + '/' + moment(toDate).format('YYYY-MM-DD')).then(r => {
    //   // console.log(r)
    //   console.log(r.data);
    //   setData(r.data);
    // });
    getData();
    // alert('x')
  };


  /* Callback invoked when the button is clicked */
  const xport = useCallback(async () => {
    /* Create worksheet from HTML DOM TABLE */
    const table = document.getElementById("Table2XLSX");
    // const wb = utils.table_to_book(table, { sheet: 'Ventas', dateNF: 'yyyy-mm-dd;@', cellDates: true, raw: true });
    // const wb = utils.table_to_book(table, { sheet: 'Ventas', dateNF: 'yyyy-mm-dd;@', cellDates: true });
    const wb = utils.table_to_book(table);



    /* Export to file (start a download) */
    writeFile(wb, "SheetJSTable.xlsx");
  });


  const dateToExcelNumber = d => {
    let date = moment(d).toDate()
    let converted = 25569.0 + ((date.getTime() - (date.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
    return converted;
  };

  return (
    <>

      <div className="card">
        <div className="card-body">
          <br/>
          <div className="row">
            <div className="col-2">
              <Form.Group controlId="dob">
                <Form.Label>Fecha Desde</Form.Label>
                <Form.Control type="date"
                value={fromDate}
                defaultValue={fromDate}
                onChange={(e) => setFromDate(e.target.value)}/>
              </Form.Group>
            </div>
            <div className="col-2">
              <Form.Group controlId="dob">
                <Form.Label>Fecha Hasta</Form.Label>
                <Form.Control type="date"
                value={toDate}
                defaultValue={toDate}
                onChange={(e) => setToDate(e.target.value)}/>
              </Form.Group>
            </div>

            <div className="col-6 mt-4">
              <Button onClick={e => handleSubmit(e)} variant="primary" type="button" className="mt-2 me-4">Mostrar</Button>
              { data && data.length > 0 && <Button onClick={xport} variant="primary" type="button" className="mt-2">Exportar</Button> }
            </div>

          </div>
        </div>
      </div>


      { data && <div className="card">
        <div className="card-body">
          <table id="Table2XLSX" className='table table-bordered table-striped'>
            <thead className="table-light">
              <tr>
                <th>Maquina</th>
                <th>Fecha</th>
                <th>Total</th>
                <th>Código</th>
                <th>Producto</th>
                <th>Precio</th>
                <th>Estado</th>
                { data.filters && data.filters.map((row, i) => {
                  return <th key={i}>{row}</th>
                }) }
              </tr>
            </thead>
            <tbody className='align-middle'>
          { data.map(row => {
            return <>
              { row.orders_details.map((detail, i) => {
                return i == 0 ?
                <tr key={row.id}>
                  <td rowSpan={row.orders_details.length}>{row.machine}</td>
                  <td data-t="n" data-v={ dateToExcelNumber(row.date) } data-z="yyyy-mm-dd" rowSpan={row.orders_details.length}>{row.date}</td>
                  <td rowSpan={row.orders_details.length}>$ {row.total}</td>
                  <td>{detail.product.code}</td>
                  <td>{detail.product.name}</td>
                  <td>$ {detail.price}</td>
                  <td>{detail.status}</td>
                  { data.filters.map((row, i) => {
                    console.log(row)
                    return <td key={i}>{detail[row]}</td>
                  }) }
                </tr> :
                <tr>
                  <td>{detail.product.code}</td>
                  <td>{detail.product.name}</td>
                  <td>$ {detail.price}</td>
                  <td>{detail.status}</td>
                  { data.filters.map((row, i) => {
                    console.log(row)
                    return <td key={i}>{detail[row]}</td>
                  }) }
                </tr>
              })}
            </>
          }) }
          </tbody>
          </table>
        </div>
      </div> }

  </>);
}

export default Data;
