import React from 'react';
import { useNavigate } from 'react-router-dom';
import { House, ListCheck, Funnel, Receipt, BarChart, Person } from 'react-bootstrap-icons';

export default function Sidebar() {

  const navigate = useNavigate();

  const goTo = (path) => {
    navigate(path);
  };

  return (
    <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
      <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
          <li className="nav-item">
            <a onClick={e => goTo('/') } href="#!" className="nav-link align-middle px-0">
              <House /> <span className="ms-1 d-none d-sm-inline">Catálogo</span>
            </a>
          </li>
          <li>
            <a onClick={e => goTo('/products') } href="#!" className="nav-link px-0 align-middle">
              <ListCheck /> <span className="ms-1 d-none d-sm-inline">Productos</span></a>
          </li>
          <li>
            <a onClick={e => goTo('/filters') } href="#!" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
              <Funnel /> <span className="ms-1 d-none d-sm-inline">Filtros</span>
            </a>
          </li>
          <li>
            <a onClick={e => goTo('/orders') } href="#!" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
              <Receipt /> <span className="ms-1 d-none d-sm-inline">Órdenes</span>
            </a>
          </li>
          <li>
            <a onClick={e => goTo('/data') } href="#!" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
              <BarChart /> <span className="ms-1 d-none d-sm-inline">Ventas</span>
            </a>
          </li>
          <li>
            <a onClick={e => goTo('/stocks') } href="#!" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
              <BarChart /> <span className="ms-1 d-none d-sm-inline">Stocks</span>
            </a>
          </li>
          <li>
            <a onClick={e => goTo('/users') } href="#!" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
              <Person /> <span className="ms-1 d-none d-sm-inline">Usuarios</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )


}
